<template>
  <div>
    <el-select
      v-model="suppliersForCreation"
      v-cancel-read-only
      class="w-full"
      filterable
      clearable
      multiple
      :multiple-limit="1"
      :disabled="disabled"
    >
      <el-option-group
        v-if="showSupplierCreateOption"
        v-permissions="{ scopes: ['supplier_management:suppliers:create'] }"
      >
        <el-option
          :label="$t('pages.suppliers.form.create_supplier')"
          @click.stop="
            $router.push({
              name: 'supplier-new',
              query: { redirectedFrom: $route.fullPath }
            })
          "
        />
      </el-option-group>
      <el-option-group v-if="suppliers.length">
        <el-option
          v-for="item in suppliers"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import unique from 'just-unique'

export default {
  props: {
    productId: {
      type: String,
      default: null
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    showSupplierCreateOption: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    propagateToVariants: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      suppliersForDeletion: [],
      suppliersForCreation: []
    }
  },
  computed: {
    suppliers() {
      return (this.resources.suppliers || []).map(({ id, companyName }) => ({
        id,
        label: companyName
      }))
    }
  },
  watch: {
    productId: {
      immediate: true,
      handler: 'fetchLinkedSupplier'
    },
    suppliersForCreation(newValue, oldValue) {
      const deletedSuppliers = oldValue.filter((id) => !newValue.includes(id))

      this.suppliersForDeletion = unique([
        ...this.suppliersForDeletion,
        ...deletedSuppliers
      ])

      this.$emit('update:modelValue', newValue)
    }
  },
  methods: {
    async fetchLinkedSupplier() {
      if (this.productId) {
        const { data } = await th
          .suppliersProductsRelation()
          .getSuppliers(this.productId)

        this.suppliersForCreation = (data?.businessPartners || []).map(
          ({ id }) => id
        )
      }
    },
    async saveLinkedSupplier(productId) {
      const suppliersProductsRelation = th.suppliersProductsRelation()
      await Promise.all(
        this.suppliersForDeletion.map((supplier) =>
          suppliersProductsRelation.delete(supplier, productId)
        )
      )
      await Promise.all(
        this.suppliersForCreation.map((supplier) =>
          suppliersProductsRelation.create(supplier, productId)
        )
      )
      // if product has variants and supplier has to be applied
      if (this.propagateToVariants) {
        const { data } = await th.products().getChildrenDetails(productId, true)
        if (!data.length) {
          return
        }

        const variantsId = { productId: data.map(({ id }) => id) }

        const relationMap = await th
          .suppliersProductsRelation()
          .getMap(variantsId)

        const variantsToSuppliersMap =
          relationMap.data.productsToBusinessPartnersMap

        const variantsSuppliersIds = Object.keys(variantsToSuppliersMap).reduce(
          (prev, next) => {
            return [...prev, ...variantsToSuppliersMap[next]]
          },
          []
        )

        await Promise.all(
          variantsSuppliersIds.map((supplier) => {
            return suppliersProductsRelation.bulkDelete(supplier, variantsId)
          })
        )

        // Currently suppliersForCreation always contains current value, even if there were no change
        // Make sure to always pass the whole current list the suppliers here
        // because in the previous action we're deleting all the suppliers from variants
        // We have to do it, because API is not providing us with an option to just SET the list of suppliers
        await Promise.all(
          this.suppliersForCreation.map((supplier) =>
            suppliersProductsRelation.bulkCreate(supplier, variantsId)
          )
        )
      }
    }
  }
}
</script>
